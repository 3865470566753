export function setImageParameters(
  imageUrl: string,
  parameters: {
    width?: number
    height?: number
    widthRatio?: number
    heightRatio?: number
    quality?: number
    mode?: "boxpad" | "crop" | "min" | "max" | "stretch"
    anchor?: "top" | "right" | "bottom" | "left" | "topleft" | "topright" | "bottomleft" | "bottomright"
  }
) {
  try {
    const url = new URL(imageUrl)
    const urlParams = url.searchParams
    if (parameters.width) {
      urlParams.set("width", parameters.width.toString())
    }
    if (parameters.height) {
      urlParams.set("height", parameters.height.toString())
    }
    if (parameters.widthRatio) {
      urlParams.set("widthratio", parameters.widthRatio.toString())
    }
    if (parameters.heightRatio) {
      urlParams.set("heightratio", parameters.heightRatio.toString())
    }
    if (parameters.mode) {
      urlParams.set("mode", parameters.mode)
    }
    if (parameters.anchor) {
      urlParams.set("anchor", parameters.anchor)
    }
    url.search = urlParams.toString()
    return url.toString()
  } catch {
    return imageUrl
  }
}
