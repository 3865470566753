import { useEffect } from "react"

const useScrollToAnchor = () => {
  useEffect(() => {
    const scrollToAnchor = () => {
      const anchorFromUrl = window.location.hash.substring(1)
      if (!anchorFromUrl) return

      const elementToScroll = document.getElementById(anchorFromUrl)
      if (!elementToScroll) return

      const scrollToElement = () => {
        window.scrollTo({
          top: elementToScroll.offsetTop - 150,
          behavior: "smooth",
        })
      }

      // Timeout is needed to scroll to the correct height of the component
      const timeoutId = setTimeout(scrollToElement, 100)

      return () => clearTimeout(timeoutId)
    }

    scrollToAnchor()
  }, [])
}

export default useScrollToAnchor
