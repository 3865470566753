import React, { useState } from "react"
import AccordionItem from "./accordion-item"

interface AccordionProps {
  items: any
}

const Accordion: React.FC<AccordionProps> = props => {
  const accordionItems = props.items[0].childItems

  const [isOpen, setIsOpen] = useState(
    Array(accordionItems.length)
      .fill(false)
      .map((_, i) => i === 0)
  )

  function handleClick(index: number) {
    const newIsOpen = Array(accordionItems.length).fill(false)
    newIsOpen[index] = true
    setIsOpen(newIsOpen)
  }

  return (
    <div className="accordion">
      {accordionItems.map((c, index) => (
        <AccordionItem
          key={index}
          title={c.content.general.title}
          description={c.content.general.description}
          link={c.content.general.link}
          buttonText={c.content.general.buttonText}
          index={index}
          isOpen={isOpen[index]}
          handleClick={handleClick}
        ></AccordionItem>
      ))}
    </div>
  )
}

export default Accordion
