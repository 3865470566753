import React from "react"
import { buildClassName } from "../../utils/classNameBuilder"
import Accordion from "../accordion/accordion"
import Image from "./image"

interface ImageWithTextProps {
  title: string
  description: string
  image: string
  accordionData: any
  hasBackground?: boolean
  reverse?: boolean
  link?: string
  linkText: string
  path: string
}

const ImageWithText: React.FC<ImageWithTextProps> = props => {
  const className = buildClassName([
    "imagewithtext",
    props.hasBackground && "imagewithtext--light",
    props.reverse && "imagewithtext--reverse",
  ])

  return (
    <section className={className}>
      <div className="imagewithtext__container">
        <Image image={props.image}></Image>
        <div className="imagewithtext__text">
          <div
            dangerouslySetInnerHTML={{
              __html: props.title
                .toString()
                .replace(/<strong>/g, '<span class="bold">')
                .replace(/<\/strong>/g, "</span>"),
            }}
          ></div>
          <div
            dangerouslySetInnerHTML={{
              __html: props.description,
            }}
          ></div>
          {props.accordionData.length === 0 ? "" : <Accordion items={props.accordionData}></Accordion>}

          {props.link === "true" ? (
            <a href={"/" + props.path} className="cta cta--secondary">
              <span>{props.linkText}</span>
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  )
}

export default ImageWithText
