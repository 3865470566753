import React, { useState } from "react"
import icons from "../../images/tide-commercial_icons.svg"
import { buildClassName } from "../../utils/classNameBuilder"
import { compact } from "lodash"

interface MobileLinkItemProps {
  path: string
  title: string
  isLast: boolean
  secondaryNavItems: any
}

const MobileLinkItem: React.FC<MobileLinkItemProps> = props => {
  const className = buildClassName([!props.isLast && "link", props.isLast && "cta cta--primary"])

  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const nameClassDropdown = compact(["dropdown__trigger", isOpenDropdown ? "dropdown__trigger--active" : ""]).join(" ")
  function dropdownClick(e) {
    e.preventDefault()
    setIsOpenDropdown(!isOpenDropdown)
  }

  return (
    <>
      {props.isLast ? (
        <>
          {props.secondaryNavItems.map((s, index) =>
            s.childItems && s.childItems.length > 0 ? (
              <li className={nameClassDropdown} key={index}>
                <a className="link" onClick={dropdownClick}>
                  {s.content.general.title}
                </a>
                <ul className="list list--plain dropdown__options ">
                  {s.childItems.map((x, index) =>
                    x.content.general.url ? (
                      <li>
                        <a key={index} href={x.content.general.url} className="link__dropdown--title">
                          {x.content.general.title}
                          <span>
                            <svg className="icon link__dropdown--container--icon">
                              <use href={`${icons}#lock`}></use>
                            </svg>
                          </span>
                        </a>
                      </li>
                    ) : (
                      <li key={index}>
                        <a key={index} href={x.content.general.path} className="link__dropdown--title">
                          {x.content.general.title}
                          <span></span>
                        </a>
                      </li>
                    )
                  )}
                </ul>
              </li>
            ) : (
              <li key={index}>
                <a className="link" href={s.content.general.path}>
                  {s.content.general.title}
                </a>
              </li>
            )
          )}
          <li>
            <a className={className} href={props.path}>
              {props.title}
            </a>
          </li>
        </>
      ) : (
        <li>
          <a className={className} href={props.path}>
            {props.title}
          </a>
        </li>
      )}
    </>
  )
}

export default MobileLinkItem
