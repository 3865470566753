import React from "react"

interface HeroContentProps {
  title: string
  description: string
  image: any
}

const HeroContent: React.FC<HeroContentProps> = props => {
  return (
    <div className="hero hero--content">
      <div className="hero__container">
        <div className="hero__copy">
          <div className="hero__title">
            <h1>
              <span
                className="bold"
                dangerouslySetInnerHTML={{
                  __html: props.title
                    .replace(/<span style="text-decoration: underline;">/g, '<span className="underline">')
                    .replace(/<\/span>/g, "</span>"),
                }}
              ></span>
            </h1>
          </div>
          <div className="hero__text" dangerouslySetInnerHTML={{ __html: props.description }}></div>
        </div>
        <div className="hero__media">
          <img src={props.image.url} className="hero__mediacontent" />
          <div className="hero__media-symbol">
            <svg xmlns="http://www.w3.org/2000/svg" width="162.164" height="132.95" viewBox="0 0 162.164 132.95">
              <defs>
                <linearGradient id="hero-linear-gradient" x1="0.947" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                  <stop offset="0" stopColor="#8fefd2" />
                  <stop offset="1" stopColor="#2dbb90" />
                </linearGradient>
              </defs>
              <path
                id="Path_546"
                data-name="Path 546"
                d="M245.613,403.066s-58.881,21.412-58.435,89.213c0-.447-2.678,40.146,29.216,63.787.224-.892,26.542-10.705,31.9-34.347C248.289,522.166,245.613,403.066,245.613,403.066Z"
                transform="matrix(-0.53, 0.848, -0.848, -0.53, 603.144, 135.981)"
                fill="url(#hero-linear-gradient)"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroContent
