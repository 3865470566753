import React from "react"

interface HeroPlainProps {
  title: string
  description: string
}

const HeroPlain: React.FC<HeroPlainProps> = props => {
  return (
    <div className="hero hero--plain">
      <div className="hero__container">
        <div className="hero__copy">
          <div className="hero__title">
            <h1
              dangerouslySetInnerHTML={{
                __html: props.title
                  .toString()
                  .replace(/<strong>/g, '<span class="bold">')
                  .replace(/<\/strong>/g, "</span>"),
              }}
            ></h1>
          </div>
          <div className="hero__text" dangerouslySetInnerHTML={{ __html: props.description }}></div>
        </div>
      </div>
    </div>
  )
}

export default HeroPlain
