import React from "react"

interface FooterItemsProps {
  path: string
  title: string
  item: any
}

const FooterItems: React.FC<FooterItemsProps> = props => {
  const children = props.item.children.map(c => (
    <li key={c.id}>
      {c.content.general.path != "" && (
        <a href={"/" + c.content.general.path} className="nav-sublink">
          {c.content.general.title}
        </a>
      )}
    </li>
  ))

  return (
    <React.Fragment>
      <li key={props.item}>
        {props.path ? (
          <a href={"/" + props.path} className="nav-link">
            {props.title}
          </a>
        ) : (
          <span className="nav-link">{props.title}</span>
        )}
      </li>
      {children}
    </React.Fragment>
  )
}

export default FooterItems
