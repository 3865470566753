import React from "react"

interface ImageProps {
  image: any
}

const Image: React.FC<ImageProps> = props => {
  return (
    <div className="imagewithtext__visual">
      <div className="imagewithtext__visual-symboltop">
        <svg xmlns="http://www.w3.org/2000/svg" width="115.996" height="108.535" viewBox="0 0 115.996 108.535">
          <defs>
            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
              <stop offset="0.005" stopColor="#ed7791" />
              <stop offset="0.774" stopColor="#f351a7" />
              <stop offset="1" stopColor="#f646af" />
            </linearGradient>
          </defs>
          <path
            id="Path_305"
            data-name="Path 305"
            d="M366.037,503.239s12.17-60.94-45.538-95.476c.378.23-32.654-22.943-69.118-8.079.64.65-4.592,28.013,12.693,44.725-.379-.23,101.963,58.83,101.963,58.83"
            transform="translate(-251.112 -394.705)"
            fill="url(#linear-gradient)"
          />
        </svg>
      </div>
      <div className="imagewithtext__visual-symbolbottom">
        <svg xmlns="http://www.w3.org/2000/svg" width="144.258" height="151.383" viewBox="0 0 144.258 151.383">
          <defs>
            <linearGradient id="linear-gradient2" x1="0.055" y1="0.859" x2="0.994" y2="0.253" gradientUnits="objectBoundingBox">
              <stop offset="0.005" stopColor="#7867ff" />
              <stop offset="0.389" stopColor="#698cff" />
              <stop offset="1" stopColor="#4fcfff" />
            </linearGradient>
          </defs>
          <path
            id="Path_318"
            data-name="Path 318"
            d="M354.9,504.248c-.8.233-20.615-15.971-41.679-9.326.351-.2-92.149,56.6-92.149,56.6s43.893,36.505,97.047,5.014c-.351.205,32.824-16.33,36.781-52.289"
            transform="matrix(0.643, 0.766, -0.766, 0.643, 294.041, -486.449)"
            fill="url(#linear-gradient2)"
          />
        </svg>
      </div>
      <div className="imagewithtext__visual-inner">
        <img src={props.image.url} alt="" />
      </div>
    </div>
  )
}

export default Image
