import React from "react"
import { buildClassName } from "../../utils/classNameBuilder"
import { setImageParameters } from "../../utils/imageResize"
import useScrollToAnchor from "../../shared/anchor-nav"

interface FeatureProps {
  label: string
  title: string
  description: string
  buttonTitle: string
  buttonLink: string
  buttonUrl: string
  anchorId: string
  img?: any
  reverse?: boolean
}

const feature: React.FC<FeatureProps> = props => {
  useScrollToAnchor()
  const className = buildClassName(["feature", props.reverse && "feature--reverse"])
  const imgUrl = setImageParameters(props.img.url, { width: 1210, height: 830, mode: "crop" })

  return (
    <div className={className} id={props.anchorId}>
      <div className="feature__text">
        <div className="feature__label">{props.label}</div>
        <div
          className="feature__title"
          dangerouslySetInnerHTML={{
            __html: props.title
              .toString()
              .replace(/<strong>/g, '<span class="bold">')
              .replace(/<\/strong>/g, "</span>"),
          }}
        ></div>
        <div className="feature__copy" dangerouslySetInnerHTML={{ __html: props.description.toString() }}></div>
        {props.buttonTitle != "" && (
          <div className="feature__cta">
            <a href={props.buttonUrl ? props.buttonUrl : "/" + props.buttonLink} className="cta cta--secondary">
              <span>{props.buttonTitle}</span>
            </a>
          </div>
        )}
      </div>
      <div className="feature__visual">
        <img src={imgUrl} alt="" />
      </div>
    </div>
  )
}

export default feature
