import React from "react"
import icons from "../../images/tide-commercial_icons.svg"

interface NavSecondaryLinkProps {
  href: string
  title: string
  childItems: any
}

const SecondaryNavLink: React.FC<NavSecondaryLinkProps> = props => {
  return (
    <div className="link link__dropdown">
      {props.href ? <a href={`/${props.href}`}>{props.title}</a> : <span className="link__dropdown--text">{props.title}</span>}
      {props.childItems && props.childItems.length > 0 ? (
        <>
          <svg className="icon link__dropdown--icon">
            <use href={`${icons}#arrow-dropdown`}></use>
          </svg>

          <div className="link__dropdown--container">
            {props.childItems.map((x, index) => (
              <a key={index} href={x.content.general.url ? x.content.general.url : x.content.general.path}>
                {x.content.general.title}
                {x.content.general.lock ? (
                  <span>
                    <svg className="icon link__dropdown--container--icon">
                      <use href={`${icons}#lock`}></use>
                    </svg>
                  </span>
                ) : null}
              </a>
            ))}
          </div>
        </>
      ) : null}
    </div>
  )
}

export default SecondaryNavLink
