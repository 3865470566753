import React from "react"

interface NavDropDownButtonProps {
  title: string
  path: string
}

const NavDropDownButton: React.FC<NavDropDownButtonProps> = props => {
  return props.path ? (
    <a href={props.path ? "/" + props.path : ""} className="dropdown__trigger">
      {props.title}
    </a>
  ) : (
    <a className="dropdown__trigger">{props.title}</a>
  )
}
export default NavDropDownButton
