import React from "react"
import { compact } from "lodash"

interface AccordionItemProps {
  index: number
  isOpen: boolean
  handleClick(index: number): void
  title: string
  description: string
  link: string
  buttonText: string
}

const AccordionItem: React.FC<AccordionItemProps> = props => {
  const nameClass = compact(["accordion__item accordion__item--click", props.isOpen ? "accordion__item--active" : ""]).join(" ")

  return (
    <div className={nameClass} onClick={() => props.handleClick(props.index)}>
      <div className="accordion__title">{props.isOpen ? <h3>{props.title}</h3> : <span>{props.title}</span>}</div>

      <div className="accordion__content">
        <div dangerouslySetInnerHTML={{ __html: props.description }} />
        {props.buttonText && (
          <a href={"/" + props.link} className="link link--witharrow">
            <span>{props.buttonText}</span>
          </a>
        )}
      </div>
    </div>
  )
}

export default AccordionItem
