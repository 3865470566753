function getTranslation(translations: object, specifier: (object) => string, language: string, fallback: string = "en-GB") {
  const value = specifier(translations[language])

  if (typeof value === "undefined") {
    return specifier(translations[fallback])
  }

  return value
}

export default getTranslation
