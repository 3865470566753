import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { init } from "cookie-though"
import cookieThoughConfigEn from "../locales/en/cookieThough.json"
import useCookieStyling from "../utils/useCookieStyling"

const Layout = ({ children }) => {
  useCookieStyling()

  if (typeof window !== "undefined" && !window.document && typeof document !== "undefined") {
    init(cookieThoughConfigEn)
  }

  return (
    <div>
      <main>{children}</main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
