import React from "react"
import icons from "../../images/tide-commercial_icons.svg"

interface MobileListItemProps {
  items: any
}

const MobileListItem: React.FC<MobileListItemProps> = props => {
  return props.items.map((i, index) => (
    <li key={index}>
      <a href={i.content.general.path} className="link link__dropdown--mobile">
        <span>{i.content.general.title}</span>
      </a>
      {i.content.general.description ? (
        <span className="dropdown__options--tags">{i.content.general.description.replace(/\n/g, " - ")}</span>
      ) : null}
    </li>
  ))
}

export default MobileListItem
