import { compact } from "lodash"
import React, { useState } from "react"
import MobileListItem from "./mobile-list-item"

interface MobileButtonProps {
  initiallsOpenDropdown: boolean
  title: string
  listItems: any
}

const MobileButton: React.FC<MobileButtonProps> = props => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(props.initiallsOpenDropdown)
  const nameClassDropdown = compact(["dropdown__trigger", isOpenDropdown ? "dropdown__trigger--active" : ""]).join(" ")
  function dropdownClick(e) {
    e.preventDefault()
    setIsOpenDropdown(!isOpenDropdown)
  }
  return (
    <li className={nameClassDropdown} key={props.title}>
      <a className="link" onClick={dropdownClick}>
        {props.title}
      </a>
      <ul className="list list--plain dropdown__options ">
        <MobileListItem items={props.listItems}></MobileListItem>
      </ul>
    </li>
  )
}

export default MobileButton
