import React from "react"

interface NavButtonProps {
  path: string
  title: string
}

const NavButton: React.FC<NavButtonProps> = props => {
  return (
    <div className="link">
      <a href={`/${props.path}`} className="cta cta--primary">
        <span>{props.title}</span>
      </a>
    </div>
  )
}

export default NavButton
