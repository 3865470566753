import React, { useEffect } from "react"

function useCookieStyling() {
  useEffect(() => {
    // Find element
    const cookieConsents = document.getElementsByClassName("cookie-though")
    if (!cookieConsents || !cookieConsents.length) {
      return
    }

    const cookieConsent = document.getElementsByClassName("cookie-though")[0]

    // Create styling
    const css = document.createElement("style")
    css.textContent = `    
        :host(.cookie-though) {
            left: initial !important;
            right: 1.5rem !important; 
        }

        body .cookie-though {
            padding: .75rem !important;
            border-radius: 0 !important;
        }
    
        body .cookie-though {
            left: initial;
            right: 1.5rem;
            border-radius: 0.4rem;
        }

        body .cookie-though.visible {
            left: initial;
            right: 1.5rem;
            border-radius: 0.4rem;
        }

        body .cookie-though.visible * {
            font-weight: 300;
        }

        .ct-banner-intro p {
            font-size: 1.2rem;
            text-transform: uppercase;
            margin-bottom: 0;
        }

        .ct-customization-button {
            position: absolute;
            width: auto;
            height: 3rem;
            top: 1rem;
            right: 1rem; 
            padding: 0 1rem;
            margin-left: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border: none;
            cursor: pointer;
        }

        .ct-customization-button svg {
            width: 3rem;
            height: 3rem;
            padding: .9rem;
            margin-left: 0.75rem;
            transform: rotate(180deg);
            border: 1px solid #d9d9d9;
            border-radius: 50%;
            background-color: #f4f4f4;
            box-sizing: border-box;
        }

        .ct-active svg {
            transform: rotate(0);
        }

        .ct-option {
            display: flex;
            border-radius: 0 !important;
            border: none !important;
        }

        .ct-option-info {
            width: 100%;
            padding: 1rem 1.5rem;
            display: flex;
            align-items: flex-start;
            cursor: pointer;
            user-select: none;
        }

        .ct-enabled {
            background-color: #eaeaff !important
        }

        .ct-enabled:not(.ct-essential) .ct-option-info p {
            color: #666 !important;
        }

        .ct-essential {
            background-color: #f9f9fe;
        }

        .ct-essential .ct-slider {
            font-size: 1.2rem;
            font-weight: 400;
            text-transform: uppercase;
            white-space: nowrap;
            color: #5c5cea !important;
        }

        input[type="checkbox"] + .ct-option-info {
            color: #666;
        }

        input[type="checkbox"] + .ct-option-info strong {
            margin-bottom: 0.5rem;
            color: #000;
        }

        .ct-option-info strong {
            font-weight: 500;
        }

        input[type="checkbox"]:not(:disabled) + label .ct-slider {
            width: 3.5rem;
            height: 2rem;
            background-color: #999;
        }

        input[type="checkbox"]:not(:disabled) + label .ct-slider:after {
            width: 1.4rem;
            height: 1.4rem;
            top: 0.3rem;
            left: 0.3rem;
            border-radius: 2rem;
            background-color: #fff !important;
        }

        .ct-declaration a {
            color: #5c5cea !important;
        }

        .ct-acceptance {
            margin-top: 0.5rem;
            display: flex;
            justify-content: flex-end;
        }

        .ct-button {
            padding: 0.8rem 1.5rem;
            flex-grow: 1;
            border: 0;
            border-radius: 0.4rem;
            font-size: 1.5rem;
            font-weight: 300;
            box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
        }

        .ct-button-secondary {
            color: #5c5cea !important;
            box-shadow: none;
        }

        .ct-button:hover,
        .ct-button:focus,
        .ct-button:active {
            box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.15);
        }

        .ct-button-secondary:hover,
        .ct-button-secondary:focus,
        .ct-button-secondary:active {
            box-shadow: none;
        }`

    // Apply styling
    cookieConsent.shadowRoot.appendChild(css)
  })
}

export default useCookieStyling
