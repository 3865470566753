import React from "react"
import getTranslation from "../../utils/get-translation"
import translations from "./translations.json"

interface NavDropDownProps {
  path: string
  navItem: any
}

const NavDropDown: React.FC<NavDropDownProps> = props => {
  return (
    <div className="dropdown__options">
      <div className="dropdown__row">
        {props.navItem.children.map((x, index) => (
          <div key={index} className="dropdown__col">
            <a href={`/${x.content.general.path}`}>
              <div className="dropdown__title">
                <svg xmlns="http://www.w3.org/2000/svg" width="17.81" height="18.236" viewBox="0 0 17.81 18.236">
                  <defs>
                    <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                      <stop offset="0.005" stopColor="#ed7791"></stop>
                      <stop offset="0.774" stopColor="#f351a7"></stop>
                      <stop offset="1" stopColor="#f646af"></stop>
                    </linearGradient>
                    <linearGradient id="linear-gradient-2" x1="0.055" y1="0.859" x2="0.994" y2="0.253" gradientUnits="objectBoundingBox">
                      <stop offset="0.005" stopColor="#7867ff"></stop>
                      <stop offset="0.389" stopColor="#698cff"></stop>
                      <stop offset="1" stopColor="#4fcfff"></stop>
                    </linearGradient>
                    <linearGradient id="linear-gradient-3" x1="0.903" y1="0.079" x2="0.272" y2="0.939" gradientUnits="objectBoundingBox">
                      <stop offset="0.001" stopColor="#8c61e5"></stop>
                      <stop offset="0.501" stopColor="#853bd1"></stop>
                      <stop offset="1" stopColor="#7f13bc"></stop>
                    </linearGradient>
                  </defs>
                  <g id="Group_2389" data-name="Group 2389" transform="translate(-187.122 -394.705)">
                    <path
                      id="Path_289"
                      data-name="Path 289"
                      d="M262.137,405.116a9,9,0,0,0-4.368-9.159,7.511,7.511,0,0,0-6.631-.775c.061.062-.441,2.687,1.218,4.291-.036-.022,9.781,5.644,9.781,5.644"
                      transform="translate(-57.308)"
                      fill="url(#linear-gradient)"
                    ></path>
                    <path
                      id="Path_290"
                      data-name="Path 290"
                      d="M235.043,494.462c-.084.024-2.153-1.668-4.352-.974.037-.021-9.622,5.91-9.622,5.91a9,9,0,0,0,10.134.524,7.511,7.511,0,0,0,3.841-5.46"
                      transform="translate(-30.401 -88.319)"
                      fill="url(#linear-gradient-2)"
                    ></path>
                    <path
                      id="Path_291"
                      data-name="Path 291"
                      d="M192.7,403.066a9.021,9.021,0,0,0-5.576,8.512,7.532,7.532,0,0,0,2.788,6.086c.021-.085,2.533-1.021,3.043-3.277C192.959,414.43,192.7,403.066,192.7,403.066Z"
                      transform="translate(0 -7.488)"
                      fill="url(#linear-gradient-3)"
                    ></path>
                  </g>
                </svg>
                {x.content.general.title}
              </div>
            </a>

            <ul>
              {x.content.general.description.split("\n").map((description, index) => (
                <li key={index}>{description}</li>
              ))}
            </ul>
            <a href={"/" + x.content.general.path} className="link link--witharrow">
              <span>{getTranslation(translations, t => t?.navDropdown.readMore, "en")}</span>
            </a>
          </div>
        ))}
        <div className="dropdown__col dropdown__cta">
          <div>
            Ready to <span className="bold">discover?</span>
          </div>
          <a href={"/contact"} className="cta cta--primary">
            <span>Get in touch</span>
          </a>
        </div>
      </div>
    </div>
  )
}
export default NavDropDown
